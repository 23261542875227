import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-default.tsx";
import MdxDefault from '../../templates/mdx-default.tsx';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = MdxDefault;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <section className="main style1">
      <header className="small">
        <h1 {...{
          "id": "lisa-naugle"
        }}>{`Lisa Naugle`}</h1>
        <h3 {...{
          "id": "emrg-faculty"
        }}>{`EMRG Faculty`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-img-attributes",
            "style": {
              "display": "block",
              "width": "60%",
              "marginLeft": "auto",
              "marginRight": "auto",
              "paddingTop": "0.75rem"
            }
          }}><span parentName="span" {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "563px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAUDAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAABnda+gKOEdKBFMFn/xAAbEAADAAIDAAAAAAAAAAAAAAABAgMABBIxM//aAAgBAQABBQIocrETGTbOK02D2GKsHI2Ken//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAwEBAAAAAAAAAAAAAAAAAQIQESEx/9oACAEBAAY/AjVLa6Ti/FSaFgz/xAAbEAEAAgIDAAAAAAAAAAAAAAABABExYRBBof/aAAgBAQABPyEJidxCk4xDgrGyyCkbmYAbIe0Lpdz2z//aAAwDAQACAAMAAAAQjDjC/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREDH/2gAIAQMBAT8QcmIfT//EABgRAQEAAwAAAAAAAAAAAAAAAAEAEBEx/9oACAECAQE/EDa4Y5f/xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRgf/aAAgBAQABPxDcZe5qKLQ79GbUO8SwFYSQgbNyoW9iNjEAJKvRnKa+GtLteWJbW2+/Z//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "lisa naugle 1x1",
                "title": "lisa naugle 1x1",
                "src": "/static/4f1c6ac5c7bdd819f2d06ad393d3dd8b/8032a/lisa-naugle_1x1.jpg",
                "srcSet": ["/static/4f1c6ac5c7bdd819f2d06ad393d3dd8b/8032a/lisa-naugle_1x1.jpg 563w"],
                "sizes": "(max-width: 563px) 100vw, 563px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
    `}</span></span></p>
      </header>
      <div className="inner">
        <p><strong parentName="p">{`Lisa Naugle`}</strong>{` is an American choreographer, artistic director, improvisor, interactive performance artist and dance educator. Recognized for her solo dance improvisation performances and choreography integrated with live video, Lisa began creating remote, geographically distanced performance in 1997 with the Cassandra Project and later coined the term, “distributed choreography" in her 2002 article, `}<em parentName="p">{`Distributed Choreography in a Video Conferencing Environment`}</em>{`. Her performance work is collaborative, interdisciplinary, often site-specific and with international collaborators. `}</p>
        <p>{`In 2009, she founded DTM2 Ensemble (Dance, Theatre, Music and Media), a collaborative arts collective. Her performance work has been presented in Eastern and Western Europe, Asia, North and South America. As Artistic Director of DTM2, Lisa is continuing to prioritize the ways in which choreography and improvisation can be integrated with other artistic forms and distributed via the Internet. `}</p>
        <p>{`In 2016-17 Lisa was selected as the Overseas Master Teacher Artist for Shanghai University and was the first American choreographer to present at the Shanghai International Dance Center.`}</p>
        <p>{`Lisa is Professor in the Department of Dance at the University of California, Irvine (UCI). She earned her Ph.D. in Dance Education from the Department of Music and Performing Arts Professions, in the Steinhardt School of Culture, Education and Human Development and her M.F.A. from Tisch School of the Arts at New York University.`}</p>
        <p>{`She is the co-founder of Eco ArtLab and Eco DesignLab, two nonprofit organizations employing art and design to respond to the escalating crisis driven by the rapidly evolving human capacity to alter planetary ecosystems. `}<a parentName="p" {...{
            "href": "http://artlab.eco"
          }}>{`Eco ArtLab`}</a>{` creates artistic performances and exhibitions that enhance ecological awareness, engaging with new forms of expression enabled by the emerging media technologies of our time. `}<a parentName="p" {...{
            "href": "http://designlab.eco"
          }}>{`Eco DesignLab`}</a>{` is developing a new online platform to support transdisciplinary artistic projects that connect body-centered performative practices with techniques for embodied human-computer interaction, drawing on insights gained from collaborative explorations in dance, music and theatre. These new initiatives are positioned as creative hubs to support synergistic groups of artists and designers concerned with environmental issues.`}</p>
        <p>{`As Director of International Initiatives in the Claire Trevor School of the Arts at UCI, Lisa leads and facilitates the development of inter-institutional collaborations to increase culturally diverse creative activities. She formerly was Chair of the UCI Department of Dance (2008-18).`}</p>
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      